import { addPropertyControls, ControlType } from "framer"

addPropertyControls(ClickTrack, {
    children: {
        type: ControlType.ComponentInstance,
    },
    eventName: {
        type: ControlType.String,
    },
    onClick: {
        type: ControlType.EventHandler,
    },
    id: {
        type: ControlType.String,
    },
    ariaLabel: {
        type: ControlType.String,
    },
})

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/docs/guides/auto-sizing
 *
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 */
export default function ClickTrack({
    id,
    ariaLabel,
    onClick,
    eventName,
    children,
    ...props
}) {
    // This is a React component containing an Example component
    // - Replace <Example /> with your own code
    // - Find inspiration: https://www.framer.com/developers/

    const handleClick = (e) => {
        console.log("handling click", e.target)

        if (!window.posthog) return

        window.posthog.capture(eventName, {
            targetAriaLabel: ariaLabel,
            targetId: id,
            targetClasses: e.target.classList,
        })
        onClick?.()
    }

    return (
        <div onClick={handleClick} aria-label={ariaLabel} id={id}>
            {children}
        </div>
    )
}
